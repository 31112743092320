


export const priceFormatter = (price) => {
    price = price.toString();
    const newPrice = price.split('');
    if (price.length === 5) {
        newPrice.splice(2, 0, ' ');
    }
    if (price.length === 6) {
        newPrice.splice(3, 0, ' ');
    }
    return newPrice.join('');
}

//   console.log(priceFormatter(90000));
