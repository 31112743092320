import './login.css';
import logo from '../../images/Group 19448@2x.png';
import eye from '../../images/view@2x.png';
import eyeBlue from '../../images/view-blue@2x.png';
import { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

export default function Login() {

    const [passwordVisible, setPasswordVisible] = useState(false);
    const { loginSubmit, changeHandler, formValues } = useContext(AuthContext);

    const seePassword = () => {
        setPasswordVisible(!passwordVisible);
    }


    return (
        <div className="login-page-container">
            <div className="login-background">
            </div>
            <div className="login-container">
                <img src={logo} className="login-logo" />
                <span>WELCOME BACK</span>
                <form className="login-form" onSubmit={loginSubmit}>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" value={formValues.email} onChange={changeHandler} />
                    <label htmlFor="password">Password</label>
                    <div className="password-field">
                        <input type={passwordVisible ? "text" : "password"} name="password" id="password" value={formValues.password} onChange={changeHandler} />
                        <img className="password-eye" src={!passwordVisible ? eye : eyeBlue} onClick={seePassword} />
                    </div>
                    <input id="login-submit" type="submit" value="LOG IN" />
                </form>
            </div>

        </div>
    )
}