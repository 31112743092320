

const url = 'https://automania.herokuapp.com/listing';



export const getAll = () => {
    return fetch(`${url}/list`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(
            {
                "pageNumber": 1,
                "pageSize": 10,
                "sortBy": "",
                "noPagination": false
            }
        )
    })
        .then(res => res.json())
        .then(result => {
            return result;
        })

        .catch(err => console.log(err));
}



export const create = (listingData, token) => {

    return fetch(`${url}/create`, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "authorization": token
        },
        body: JSON.stringify(listingData)
    })
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => console.log(err));
}


export const edit = (listingId, listingData, token) => {

    return fetch(`${url}/${listingId}`, {
        method: "PUT",
        headers: {
            "Content-type": "application/json",
            "authorization": token
        },
        body: JSON.stringify(listingData)
    })
        .then(res => res.json())
        .then(result => {
            return result;
        })
        .catch(err => console.log(err));
}


export const deleteListing = (listingId, token) => {
    return fetch(`${url}/${listingId}`, {
        method: "DELETE",
        headers: {
            'authorization': token
        }
    })
        .then(res => res.json())
        .catch(err => console.log(err))
}


export const getOne = (listingId) => {
    return fetch(`${url}/${listingId}`, {
        method: "GET",
        headers: {
            "Content-type": "application/json",
        }
    })
        .then(res => res.json())
        .then(result => {
            return result.payload;
        })
        .catch(err => console.log(err))
}


export const upload = (formData, token) => {
    return fetch("https://automania.herokuapp.com/file/upload", {
        method: "POST",
        headers: {
            'authorization': token
        },
        body: formData
    })
        .then((res) => res.json())
        .then((result) => {
            const urls = result.payload.map((image) => image.url);
            // console.log(urls);
            return urls;
        })
        .catch(err => console.log(err))
}