import './deleteModal.css'
import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ListingContext } from '../../context/ListingContext'

export default function DeleteModal() {

    const { onDeleteClick } = useContext(ListingContext);
    const { listingId } = useParams();

    return (
        <div className="delete-modal-container">
            <div className="delete-modal">
                <span id="delete-listing-span">DELETE LISTING</span>
                <span id="delete-confirmation-span">Are you sure you want to delete this listing from the platform?</span>
                <div className="modal-buttons">
                    <Link to="/" className="go-back-btn">GO BACK</Link>
                    <Link className="delete-btn" onClick={(e) => onDeleteClick(e, listingId)}>DELETE</Link>
                </div>
            </div>
        </div>
    )
}