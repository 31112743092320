import './home.css';
import { Link, Outlet } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import { getAll } from '../../services/listingService';
import { useContext, useEffect, useState } from 'react';
import { priceFormatter } from '../../utils/priceFormatter';
import settingIcon from '../../images/setting@2x.png';
import bin from '../../images/Path 59473@2x.png';
import pencil from '../../images/pencil@2x.png';
import { AuthContext } from '../../context/AuthContext';
import { ListingContext } from '../../context/ListingContext';

export default function Home() {

    const { listings,
        setListings,
        onEditClick,
        mobileModalToggle,
        setMobileModalToggle,
        dropdownMobileToggle,
        setDropdownMobileToggle,
     } = useContext(ListingContext);

    const { auth } = useContext(AuthContext);
    const userId = auth.userId;

    

    useEffect(() => {
        getAll()
            .then(result => {
                setListings(result.payload.docs);
            })
    }, [])


    const toggleDropdown = (id) => {
        setListings((prevListings) =>
            prevListings.map((listing) =>
                listing._id === id
                    ? { ...listing, dropdownToggled: !listing.dropdownToggled }
                    : listing
            )
        );
        setDropdownMobileToggle(true);
        setMobileModalToggle(true)
    }

 

    const closeMobileModal = () => {
        setDropdownMobileToggle(false);
        setMobileModalToggle(false);
    }


    return (
        <>

            <Outlet />
            <Navigation />


            <div className="listings-container">
                <div className="listings">
                    <span>CAR LISTINGS ({listings.length})</span>
                    <div className="cars-container">

                        <div className="car-listings">
                            {listings.map(x => {
                                return (
                                    <div key={x._id} className="car-container">
                                        {x.user._id === userId &&
                                            <div className="manage-button-container">
                                                <div className="manage-button" onClick={() => toggleDropdown(x._id)}><img src={settingIcon} className="setting-icon" />
                                                    <span>MANAGE</span>
                                                </div>

                                                {x.dropdownToggled &&
                                                    <>
                                                        <div className="manage-dropdown">
                                                            <Link onClick={(e) => onEditClick(e, x._id)} className="dropdown-edit"><img className="dropdown-edit-image" src={pencil} />Edit listing</Link>
                                                            <Link to={`/listing/${x._id}/delete`} className="dropdown-delete"><img className="dropdown-delete-image" src={bin} />Delete listing</Link>
                                                        </div>

                                                        {/* mobile modal */}
                                                        <div className="manage-dropdown-mobile" style={{ display: dropdownMobileToggle ? 'flex' : 'none' }}>
                                                            <span>Please select</span>
                                                            <Link onClick={(e) => onEditClick(e, x._id)} className="dropdown-edit"><img className="dropdown-edit-image" src={pencil} />Edit listing</Link>
                                                            <Link to={`/listing/${x._id}/delete`} className="dropdown-delete"><img className="dropdown-delete-image" src={bin} />Delete listing</Link>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }
                                        <img src={x.mainPhoto} />
                                        <div className="car-name-price-container">
                                            <span id="car-name">{x.brand} {x.model}</span>
                                            <span id="car-price">{priceFormatter(x.price)} BGN</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>

                </div>
            </div>

            <div className="manage-mobile-modal" style={{ display: mobileModalToggle ? 'flex' : 'none' }} onClick={closeMobileModal}></div>

        </>
    )
}
