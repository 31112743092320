import { Routes, Route } from 'react-router-dom';
import Login from '../Login/Login';
import Home from '../Home/Home';
import AddListing from '../AddListing/AddListing';
import DeleteModal from '../DeleteModal/DeleteModal';

export default function RoutesComp() {
    return (
        <Routes>
            <Route path="/" element={<Home />}>
                <Route path="/listing/:listingId/delete" element={<DeleteModal />}></Route>
            </Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/add" element={<AddListing />}></Route>
            <Route path="/listing/:listingId/edit" element={<AddListing />}></Route>

        </Routes>
    )
}