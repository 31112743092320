const url = 'https://automania.herokuapp.com/user';

export const login = (userData) => {
    return fetch(`${url}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
    })
        .then(res => {
            if (res.ok) {
                return res.json();
            }
        })

        .catch(err => {
            console.log(`Error: ${err}`);
        })
}