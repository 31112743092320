import './addListing.css'
import xIcon from '../../images/Group 19454@2x.png';
import Navigation from "../Navigation/Navigation";
import { useContext, useEffect } from 'react';
import redX from '../../images/redX.png';
import { edit } from '../../services/listingService';
import { ListingContext } from '../../context/ListingContext';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';

export default function AddListing() {

    const navigate = useNavigate();
    const { listingId } = useParams();
    console.log(listingId);

    const { auth } = useContext(AuthContext);
    const token = auth?.token;


 

    const onEditSubmit = (e) => {
        e.preventDefault();

        edit(listingId, inputValues, token)
            .then(() => {

                setInputValues({
                    model: '',
                    brand: '',
                    price: '',
                    mainPhoto: '',
                    additionalPhotos: []
                })
                handleDeleteMainImage();
                handleDeleteImages();

                navigate('/');
            })
    }

    const handleSubmit = (e) => {
        if (listingId) {
            onEditSubmit(e);
        } else {
            onCreateSubmit(e);
        }
    }


    const { mainPhotoName,
        uploadedFileNames,
        handleMainImageUpload,
        handleImageUpload,
        handleDeleteMainImage,
        handleDeleteImages,
        changeHandler,
        onCreateSubmit,
        inputValues,
        setInputValues,
        submitRef,
        mainPhotoRef,
        additionalPhotosRef } = useContext(ListingContext);

    return (
        <>
            <Navigation />

            <div className="add-listing-container">
                <div className="new-listing-container">
                    <div className="new-listing-heading">
                        <div className="new-listing">
                            <img src={xIcon} className="x-icon" onClick={() => navigate('/')} />
                            <span>NEW LISTING</span>
                        </div>
                        <input id="save-listing" type="submit" value="SAVE LISTING" onClick={handleSubmit} />
                    </div>
                    <div className="new-listing-general-info">
                        <span id="general-info">GENERAL INFORMATION</span>

                        <form className="new-listing-form" onSubmit={listingId ? onEditSubmit : onCreateSubmit} ref={submitRef}>
                            <div className="listing-form-container">
                                <div className="new-listing-form-section" id="new-listing-brand">
                                    <label htmlFor="brand">Brand</label>
                                    <input name="brand" type="text" value={inputValues.brand} onChange={changeHandler} />
                                </div>
                                <div className="new-listing-form-section">
                                    <label htmlFor="model">Model</label>
                                    <input name="model" type="text" value={inputValues.model} onChange={changeHandler} />
                                </div>
                                <div className="new-listing-form-section" id="form-price-section">
                                    <label htmlFor="price">Price</label>
                                    <input name="price" type="text" value={inputValues.price} onChange={changeHandler} />
                                    <span>BGN</span>
                                </div>
                            </div>
                            <div className="form-photos">
                                <span id="new-listing-photos-span">PHOTOS</span>
                                <div className="form-photos-container">
                                    <div className="form-photos-section" id="form-photos-main-section">
                                        <span>Main photo</span>
                                        {mainPhotoName
                                            ?
                                            <div className="photo-uploaded">
                                                {mainPhotoName && <div>{mainPhotoName}
                                                    <img src={redX} className="red-x" onClick={handleDeleteMainImage} />
                                                </div>}
                                            </div>
                                            :
                                            <>
                                                <label htmlFor="mainPhoto">UPLOAD</label>
                                                <label htmlFor="mainPhoto" id="photos-label-mobile"></label>
                                                <input name="mainPhoto" type="file" className="photo-input" ref={mainPhotoRef} onChange={handleMainImageUpload} />
                                            </>
                                        }
                                    </div>
                                    <div className="form-photos-section">
                                        <span>Additional photos</span>
                                        <label htmlFor="additionalPhotos">UPLOAD</label>
                                        <label htmlFor="additionalPhotos" id="photos-label-mobile"></label>
                                        <input name="additionalPhotos" type="file" className="photo-input" multiple ref={additionalPhotosRef} onChange={handleImageUpload} />
                                    </div>
                                    <div className="uploaded-image-container">
                                        {uploadedFileNames.length !== 0 &&
                                            uploadedFileNames.map((fileName, index) => (
                                                <div key={index} className="photo-uploaded">{fileName}
                                                    <img src={redX} className="red-x" onClick={() => handleDeleteImages(fileName)} />
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>

                            </div>
                        </form>
                        <input id="save-listing-mobile" type="submit" value="SAVE LISTING" onClick={handleSubmit} />

                    </div>
                </div>
            </div>

        </>

    )
}