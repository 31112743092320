import { createContext, useRef, useState, useContext, useEffect } from "react";
import { create, deleteListing, getOne, upload } from "../services/listingService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export const ListingContext = createContext();



export const ListingProvider = ({
    children
}) => {

    const navigate = useNavigate();
    const submitRef = useRef(null);

    const [listings, setListings] = useState([]);

    const { auth } = useContext(AuthContext);
    const token = auth?.token;

    const mainPhotoRef = useRef();
    const additionalPhotosRef = useRef();
    const [mainPhoto, setMainPhoto] = useState(null);
    const [mainPhotoName, setMainPhotoName] = useState('');
    const [uploadedFileNames, setUploadedFileNames] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [mainPhotoUrl, setMainPhotoUrl] = useState('');
    const [photoUrls, setPhotoUrls] = useState([]);

    const [mobileModalToggle, setMobileModalToggle] = useState(false);
    const [dropdownMobileToggle, setDropdownMobileToggle] = useState(false);


    const [inputValues, setInputValues] = useState({
        model: '',
        brand: '',
        price: '',
        mainPhoto: '',
        additionalPhotos: []
    })



    const onCreateSubmit = (e) => {
        e.preventDefault();

        console.log(mainPhotoUrl);
        console.log(inputValues);

        create(inputValues, token)
            .then(() => {

                setInputValues({
                    model: '',
                    brand: '',
                    price: '',
                    mainPhoto: '',
                    additionalPhotos: []
                })
                handleDeleteMainImage();
                handleDeleteImages();

                navigate('/');
            })
    }



    const changeHandler = (e) => {
        setInputValues(state => ({ ...state, [e.target.name]: e.target.value }));
    }

    const handleMainImageUpload = async (e) => {
        const file = e.target.files[0];

        if (file) {
            setMainPhoto(file);
            setMainPhotoName(file.name);
            const mainPhotoUrl = await onMainPhotoUpload(file);
            setMainPhotoUrl(mainPhotoUrl);


        } else {
            setMainPhoto('');
            setMainPhotoName('');
            setMainPhotoUrl('');
        }
    }

    useEffect(() => {
        if (mainPhoto) {
            setInputValues((state) => ({ ...state, mainPhoto: mainPhotoUrl }));
        }

    }, [mainPhotoUrl]);


    useEffect(() => {

        if (uploadedFileNames.length > 0) {
            setInputValues((state) => ({ ...state, additionalPhotos: photoUrls }));
        }
    }, [photoUrls]);


    const handleImageUpload = async (e) => {
        const files = Array.from(e.target.files);
        const fileNames = files.map((file) => file.name);


        if (files.length > 0) {

            setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
            setUploadedFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);
            for (const file of files) {
                const url = await onPhotosUpload(file);
                setPhotoUrls((prevPhotoUrls) => [...prevPhotoUrls, url]);
            }
        }


    }


    const onMainPhotoUpload = async (file) => {
        const formData = new FormData();
        formData.append("images", file);

        upload(formData, token)
            .then((url) => {
                setMainPhotoUrl(url[0])
                return url[0];
            })

    };


    const onPhotosUpload = async (file) => {
        const formData = new FormData();
        formData.append("images", file);

        upload(formData, token)
            .then((url) => {
                setPhotoUrls((prevUrls) => ([...prevUrls, url[0]].filter(x => x !== undefined)))
                return url[0];

            })

    };



    const handleDeleteMainImage = () => {
        setMainPhoto('');
        setMainPhotoName('');
        setMainPhotoUrl('')
        setInputValues((state) => ({ ...state, mainPhoto: '' }));
    }

    const handleDeleteImages = (fileName) => {
        setUploadedFiles((files) => files.filter((x) => x !== fileName));
        setUploadedFileNames((files) => files.filter((x) => x !== fileName));

        const updatedPhotoUrls = photoUrls.filter((url) => url !== fileName);
        setPhotoUrls(updatedPhotoUrls)

        setInputValues((state) => ({
            ...state,
            additionalPhotos: photoUrls
        }));
    }




    const onDeleteClick = (e, listingId) => {
        e.preventDefault();
        deleteListing(listingId, token)
            .then(() => {
                setListings((prevListings) => prevListings.filter((listing) => listing._id !== listingId));
                setDropdownMobileToggle(false);
                setMobileModalToggle(false);
                navigate('/');
            })
            .catch(err => console.log(err))
    }

    const onEditClick = (e, listingId) => {
        e.preventDefault();
        navigate(`/listing/${listingId}/edit`);

        getOne(listingId)
            .then((result) => {
                setInputValues({
                    model: result.model,
                    brand: result.brand,
                    price: result.price,
                    mainPhoto: mainPhoto,
                    additionalPhotos: result.additionalPhotos,
                })
            })
    }

    const onAddClick = () => {
        setInputValues({
            model: '',
            brand: '',
            price: '',
            mainPhoto: '',
            additionalPhotos: []
        })

    }


    const contextValues = {
        mainPhoto,
        mainPhotoName,
        uploadedFileNames,
        inputValues,
        setInputValues,
        handleMainImageUpload,
        handleImageUpload,
        handleDeleteMainImage,
        handleDeleteImages,
        changeHandler,
        onCreateSubmit,
        submitRef,
        mainPhotoRef,
        additionalPhotosRef,
        onDeleteClick,
        onEditClick,
        listings,
        setListings,
        mobileModalToggle,
        setMobileModalToggle,
        dropdownMobileToggle,
        setDropdownMobileToggle,
        onAddClick
    }

    return (
        <ListingContext.Provider value={contextValues}>
            {children}
        </ListingContext.Provider>
    )
}