import './navigation.css';
import logoDesktop from '../../images/Group 19448@2x.png';
import profileIcon from '../../images/profile@2x.png';
import profileIconBlue from '../../images/profileBlue@2x.png';
import addListingIcon from '../../images/Group 19449@2x.png';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ListingContext } from '../../context/ListingContext';

export default function Navigation() {

    const {onAddClick} = useContext(ListingContext);
    const { auth } = useContext(AuthContext);
    const token = !!auth.token;
    const firstName = (auth.name).split(' ')[0].toUpperCase();

    return (
        <nav className="navigation">
            <div className="nav-container">
                <img src={logoDesktop} className="logo" />
                <div className="nav-login-add">
                    <div className="nav-login-container">
                        {token ?
                            <div className="nav-login">
                                <img src={profileIconBlue} className="profile-icon" />
                                <span className="nav-greet-span">HI, {firstName}</span>
                            </div>
                            :
                            <Link to="/login" className="nav-login">
                                <img src={profileIcon} className="profile-icon" />
                                <span className="nav-login-span">LOG IN</span>
                            </Link>
                        }
                    </div>
                    <Link to="/add" className="nav-add-listing" onClick={onAddClick}>
                        <img src={addListingIcon} className="add-listing-icon" />
                        <span>ADD LISTING</span>
                    </Link>
                </div>
            </div>
        </nav>
    )

}