
import './App.css';
import Navigation from './components/Navigation/Navigation';
import RoutesComp from './components/Routes/RoutesComp';
import { AuthProvider } from './context/AuthContext';
import { ListingProvider } from './context/ListingContext';

function App() {
  return (
    <AuthProvider>
      <ListingProvider>
        <div className="App">
          <main>
            <RoutesComp />
          </main>
        </div>
      </ListingProvider>
    </AuthProvider>
  );
}

export default App;
