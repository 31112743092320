import { useState } from "react"


export const useLocalStorage = (key, initialValues) => {

    const serializedUserName = localStorage.getItem(key.name);
    const serializedToken = localStorage.getItem(key.token);
    const serializedUserId = localStorage.getItem(key.userId);

    const [auth, setAuth] = useState(() => {

        if (serializedToken && serializedUserId && serializedUserName) {
            const token = JSON.parse(serializedToken);
            const userId = JSON.parse(serializedUserId);
            const name = JSON.parse(serializedUserName);

            return {
                name,
                token,
                userId,
            }
        }
        return initialValues;
    })

    const setLocalStorage = (name, token, userId) => {
        localStorage.setItem(key.name, JSON.stringify(name));
        localStorage.setItem(key.token, JSON.stringify(token));
        localStorage.setItem(key.userId, JSON.stringify(userId));

        setAuth({
            name,
            token,
            userId
        })
    }

    return [
        auth,
        setLocalStorage
    ]

}