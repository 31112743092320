import { createContext, useState } from "react";
import { login } from "../services/userService";
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from "../hooks/useLocalStorage";


export const AuthContext = createContext();


export const AuthProvider = ({
    children
}) => {
    const navigate = useNavigate();


    const [auth, setAuth] = useLocalStorage({ name: 'name', token: 'token', userId: 'userId' },
        //initial values 
        {
            name: '',
            token: '',
            userId: '',
        });

    const [formValues, setFormValues] = useState({
        email: 'miroo2306@gmail.com',
        password: '123456',
    })

    const changeHandler = (e) => {
        setFormValues(state => ({ ...state, [e.target.name]: e.target.value }));
    }


    const loginSubmit = (e) => {
        e.preventDefault();

        login(formValues)
            .then(result => {
                if (result) {
                    setAuth(
                        result.user.fullName,
                        result.token,
                        result.user._id,
                    )
                    setFormValues({
                        email: '',
                        password: '',
                    }
                    )
                    navigate('/')
                } else {
                    alert('Incorrect email or password!')
                }
            })

            .catch(err => console.log(err))

    }

    const contextValues = {
        loginSubmit,
        changeHandler,
        formValues,
        auth
    }

    return (
        <AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>
    )
}